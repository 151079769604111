"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, type ReactNode } from "react";

//NOTE: Only import posthog directly on this file, where it is being initialed. To use it throughout the app, use the usePostHog hook.

// This will only be used on the client side.

export function CSPostHogProvider({
  children,
  publicKey,
}: {
  children: ReactNode;
  publicKey?: string;
}) {
  //Needs to be initialized inside the useEffect to avoid errors
  useEffect(() => {
    if (publicKey) {
      posthog.init(publicKey, {
        api_host: "https://us.i.posthog.com",
        person_profiles: "always",
        capture_pageview: true,
      });
    }
  }, [publicKey]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
